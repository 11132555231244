import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-template-cate',
  templateUrl: './template-cate.page.html',
  styleUrls: ['./template-cate.page.scss'],
})
export class TemplateCatePage implements OnInit {
  category: any = '';
  templates: any = [];
  selectedArray: any = [];

  constructor(
    private userServ: UserService,
    private navParams: NavParams,
    private modalCtrl: ModalController
  ) {
    if (this.navParams.get('templatecate')) {
      this.category = this.navParams.get('templatecate');
      console.log(this.category);
    }
  }

  async ngOnInit() {
    this.templates = await this.userServ.getTemplates(this.category);
    console.log(this.templates);
  }
  async closeModal() {
    await this.modalCtrl.dismiss({});
  }
  async templatedata(data: any) {
    await this.modalCtrl.dismiss({ data });
  }
  async submitform() {
    console.log(this.templates);
    let templateText = '';
    this.templates.forEach(template => {
      if (template.isChecked) {
        templateText += template.template_text + '\n'
      }
    });
    console.log(templateText);
    await this.modalCtrl.dismiss({ data: templateText });
  }

}
