import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ChektemplatePage } from './chektemplate.page';

const routes: Routes = [
  {
    path: '',
    component: ChektemplatePage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ChektemplatePageRoutingModule {}
