import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { User } from 'src/app/interfaces/user.data';
import * as Constants from 'src/app/shared/constants'
import { FormService } from 'src/app/services/form/form.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userObj = new User();
  public user: BehaviorSubject<User> = new BehaviorSubject<User>(this.userObj);
  public newMessages: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private awaitResp = false;
  private authkey = '';
  constructor(
    private authServ: AuthService,
    private formServ: FormService,) {
    this.init();
  }
  async init() {
    // console.log('init user service');
    this.authkey = await this.authServ.getAuthkey();
    if (this.authkey) {
      console.log(this.authkey);
      this.getUserProfile();
    }
  }
  async getUserProfile() {
    while (this.awaitResp) {
      await new Promise(resolve => setTimeout(resolve, 500));
    }
    this.awaitResp = true;
    if (!this.userObj.mobile || this.userObj.mobile.toString().length < 7) {
      await this.getUserProfileFromServer();
    } else {
      // console.log('return local');
    }
    this.awaitResp = false;
    return this.userObj;
  }
  async getUserProfileFromServer() {
    // console.log('getting from server');
    const url = Constants.USER_API_PATH + 'profile';
    const resp = await this.formServ.httpPost({}, url);
    console.log(resp);
    if (resp) {
      this.userObj = resp;
      this.userObj.loggedIn = true;
      this.user.next(this.userObj);
    }
    console.log(this.userObj);
    return this.userObj;
  }
  async userLogin(logindata) {
    console.log(logindata)
    const url = Constants.PUB_API_PATH + 'user_login';
    const apiResp = await this.formServ.httpPost(logindata, url);
    if (apiResp && apiResp.authkey) {
      this.authkey = apiResp.authkey;
      this.formServ.authkey = this.authkey;
      this.authServ.setAuthkey(this.authkey);
      this.getUserProfileFromServer();
    }
    return apiResp;
  }
  async states() {
    const url = Constants.USER_API_PATH + 'getStates';
    const resp = await this.formServ.httpPost({}, url);
    if (resp.states) {
      return resp.states;
    } else {
      return [];
    }
  }
  async newClient(clientdata) {
    const url = Constants.USER_API_PATH + 'new_client';
    const apiResp = await this.formServ.httpPost(clientdata, url);
    return apiResp;
  }
  async kemcoclients() {
    const url = Constants.USER_API_PATH + 'getClients';
    const resp = await this.formServ.httpPost({}, url);
    if (resp.clients) {
      return resp.clients;
    } else {
      return [];
    }
  }
  async kemcosetting() {
    const url = Constants.USER_API_PATH + 'getsetting';
    const resp = await this.formServ.httpPost({}, url);
    if (resp.setting) {
      return resp.setting;
    } else {
      return {};
    }
  }
  async kemcoclient(clientid) {
    const url = Constants.USER_API_PATH + 'getClient/' + clientid;
    const resp = await this.formServ.httpPost({}, url);
    if (resp.client) {
      return resp.client;
    } else {
      return [];
    }
  }
  async getcategories() {
    const url = Constants.USER_API_PATH + 'getCategory';
    const resp = await this.formServ.httpPost({}, url);
    if (resp.categories) {
      return resp.categories;
    } else {
      return [];
    }
  }
  async getTemplates(category) {
    const url = Constants.USER_API_PATH + 'catetemplates/' + category;
    const resp = await this.formServ.httpPost({}, url);
    if (resp.templates) {
      return resp.templates;
    } else {
      return [];
    }
  }
  async getTemplate(category, templateid) {
    const url = Constants.USER_API_PATH + 'getTemplate/' + category + '/' + templateid;
    const resp = await this.formServ.httpPost({}, url);
    if (resp.template) {
      return resp.template;
    } else {
      return [];
    }
  }
  async newtemplate(templatedata) {
    const url = Constants.USER_API_PATH + 'new_template';
    const apiResp = await this.formServ.httpPost(templatedata, url);
    return apiResp;
  }
  async removetemplate(templateid) {
    const url = Constants.USER_API_PATH + 'remove_template/' + templateid;
    const apiResp = await this.formServ.httpPost({}, url);
  }
  async newQuotation(qtndata) {
    const url = Constants.USER_API_PATH + 'new_quotation';
    const apiResp = await this.formServ.httpPost(qtndata, url);
    return apiResp;
  }
  async getQuotations() {
    const url = Constants.USER_API_PATH + 'get_quotations';
    const resp = await this.formServ.httpPost({}, url);
    if (resp.quotations) {
      return resp.quotations;
    } else {
      return [];
    }
  }
  async getQtnItms(qtnid) {
    const url = Constants.USER_API_PATH + 'get_quotation_items/' + qtnid;
    const resp = await this.formServ.httpPost({}, url);
    if (resp.qtnitems) {
      return resp.qtnitems;
    } else {
      return [];
    }
  }
  async newQuotationItem(itemdata) {
    const url = Constants.USER_API_PATH + 'new_quotation_item';
    const apiResp = await this.formServ.httpPost(itemdata, url);
    return apiResp;
  }
  async QuotationUpdateOne(qtninfodata) {
    const url = Constants.USER_API_PATH + 'quotation_info_one';
    const apiResp = await this.formServ.httpPost(qtninfodata, url);
    return apiResp;
  }

  async QuotationUpdateTwo(qtninfodata) {
    const url = Constants.USER_API_PATH + 'quotation_info_two';
    const apiResp = await this.formServ.httpPost(qtninfodata, url);
    return apiResp;
  }
  async QuotationUpdateThree(qtninfodata) {
    const url = Constants.USER_API_PATH + 'quotation_info_three';
    const apiResp = await this.formServ.httpPost(qtninfodata, url);
    return apiResp;
  }
  async QuotationUpdateFour(qtninfodata) {
    const url = Constants.USER_API_PATH + 'quotation_info_four';
    const apiResp = await this.formServ.httpPost(qtninfodata, url);
    return apiResp;
  }
  async get_quotation(qtnid) {
    const url = Constants.USER_API_PATH + 'get_quotation/' + qtnid;
    const resp = await this.formServ.httpPost({}, url);
    if (resp.quotation) {
      return resp.quotation;
    } else {
      return [];
    }
  }
  /*Repair quotation function*/
  async getrcategories() {
    const url = Constants.USER_API_PATH + 'repair_category/';
    const resp = await this.formServ.httpPost({}, url);
    if (resp.categories) {
      return resp.categories;
    } else {
      return [];
    }
  }
  async getqrTemplates(qrcategory) {
    const url = Constants.USER_API_PATH + 'qrcatetemplates/' + qrcategory;
    const resp = await this.formServ.httpPost({}, url);
    if (resp.templates) {
      return resp.templates;
    } else {
      return {};
    }
  }
  async getqrTemplate(qrcategory, rqtnid) {
    const url = Constants.USER_API_PATH + 'get_qrcatetemplate/' + qrcategory + '/' + rqtnid;
    const resp = await this.formServ.httpPost({}, url);
    if (resp.template) {
      return resp.template;
    } else {
      return {};
    }
  }
  async qrnewtemplate(templatedata) {
    const url = Constants.USER_API_PATH + 'qrnew_template';
    const apiResp = await this.formServ.httpPost(templatedata, url);
    return apiResp;
  }
  async removeqrtemplate(templateid) {
    const url = Constants.USER_API_PATH + 'remove_qrtemplate/' + templateid;
    const apiResp = await this.formServ.httpPost({}, url);
  }
  async rnewQuotation(qtndata) {
    const url = Constants.USER_API_PATH + 'rnew_quotation';
    const apiResp = await this.formServ.httpPost(qtndata, url);
    return apiResp;
  }
  async newQRItem(qritemdata) {
    const url = Constants.USER_API_PATH + 'newqr_item';
    const apiResp = await this.formServ.httpPost(qritemdata, url);
    return apiResp;
  }
  async rquotationUpdate(qtninfodata) {
    const url = Constants.USER_API_PATH + 'rquotation_update';
    const apiResp = await this.formServ.httpPost(qtninfodata, url);
    return apiResp;
  }
  async get_rquotation(qtnid) {
    const url = Constants.USER_API_PATH + 'get_rquotation/' + qtnid;
    const resp = await this.formServ.httpPost({}, url);
    if (resp.rquotation) {
      return resp.rquotation;
    } else {
      return {};
    }
  }
  async getRQtnItms(qtnid) {
    const url = Constants.USER_API_PATH + 'get_rquotation_items/' + qtnid;
    const resp = await this.formServ.httpPost({}, url);
    if (resp.rqtnitems) {
      return resp.rqtnitems;
    } else {
      return [];
    }
  }
  async getRQuotations() {
    const url = Constants.USER_API_PATH + 'get_rquotations';
    const resp = await this.formServ.httpPost({}, url);
    if (resp.rquotations) {
      return resp.rquotations;
    } else {
      return [];
    }
  }
  async logout() {
    this.userObj = new User();
    this.user.next(this.userObj);
    this.authServ.clear();
  }
}

