import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { User } from 'src/app/data-types/user.object';
// import { ForgotPasswordPage } from '../forgot-password/forgot-password.page';
import * as constants from 'src/app/shared/constants'

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  CONST = constants;
  logindata: any = {};
  user: User = new User();
  passwordType: string = 'password';
  passwordIcon: string = 'eye-off';
  verivicationchek: any = '';
  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private userServ: UserService
  ) { }

  ngOnInit() {
  }
  async submitlogin() {

    const resp = await this.userServ.userLogin(this.logindata);
    console.log(resp);
    if (resp) {
      const alert = await this.alertCtrl.create({
        message: 'You are sucessfully login',
      });
      await alert.present();
      setTimeout(() => {
        alert.dismiss();
      }, 1000);
      this.closeModal();
    }
  }
  async closeModal() {
    await this.modalCtrl.dismiss({});
  }
  hideShowPassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  async submitform() {
    const valid = await this.loginvalidation();
    if (valid == 'valid') {
      this.submitlogin();
    } else {
      const alert = await this.alertCtrl.create({
        header: 'Validation Error',
        message: valid,
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {

            }
          }
        ]
      });
      await alert.present();
    }
  }
  async loginvalidation() {
    if (!this.logindata.email) {
      return 'Enter your Email Or Register Phone number.';
    }
    if (!this.logindata.password) {
      return 'Enter your email.';
    }
    return 'valid';
  }
}
