import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ListtemplatePageRoutingModule } from './listtemplate-routing.module';

import { ListtemplatePage } from './listtemplate.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ListtemplatePageRoutingModule
  ],
  declarations: [ListtemplatePage]
})
export class ListtemplatePageModule {}
