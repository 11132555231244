import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChektemplatePageRoutingModule } from './chektemplate-routing.module';

import { ChektemplatePage } from './chektemplate.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ChektemplatePageRoutingModule
  ],
  declarations: [ChektemplatePage]
})
export class ChektemplatePageModule {}
