import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/data-types/user.object';
import { NavController, AlertController, ModalController } from '@ionic/angular';
import { LoginPage } from 'src/app/pages/login/login.page';


@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  user: User = new User();
  memberOpen = false;
  constructor(
    private alertCtrl: AlertController,
    private navCtrl: NavController,
    private userServ: UserService,
    private modalCtrl: ModalController,

  ) { }

  async ngOnInit() {
    await this.userServ.user.subscribe(async u => {
      this.user = u;
    });
  }
  openMemberMenu() {
    const curState = this.memberOpen;
    this.memberOpen = !curState;
  }
  async login() {
    const modal = await this.modalCtrl.create({
      component: LoginPage,
    });
    modal.onDidDismiss().then(async (resp) => {
    })
    await modal.present();
  }

}
