import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { RouterModule } from "@angular/router";
import { UserMenuComponent } from "./user-menu/user-menu.component";
import { TopBarComponent } from "./top-bar/top-bar.component";
import { FooterComponent } from "./footer/footer.component";
@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        RouterModule
    ],
    declarations: [
        TopBarComponent,
        UserMenuComponent,
        FooterComponent,
    ],
    exports: [
        TopBarComponent,
        UserMenuComponent,
        FooterComponent,
    ]
})
export class ComponentsModule { }