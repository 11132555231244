import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CategoryTemplatePageRoutingModule } from './category-template-routing.module';

import { CategoryTemplatePage } from './category-template.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CategoryTemplatePageRoutingModule
  ],
  declarations: [CategoryTemplatePage]
})
export class CategoryTemplatePageModule {}
