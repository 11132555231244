import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-listtemplate',
  templateUrl: './listtemplate.page.html',
  styleUrls: ['./listtemplate.page.scss'],
})
export class ListtemplatePage implements OnInit {
  category: any = '';
  templates: any = [];
  constructor(
    private userServ: UserService,
    private navParams: NavParams,
    private modalCtrl: ModalController
  ) {
    if (navParams.get('templatecate')) {
      this.category = navParams.get('templatecate');
      console.log(this.category);
    }
  }

  async ngOnInit() {
    this.templates = await this.userServ.getqrTemplates(this.category);
    console.log(this.templates);
  }
  async closeModal() {
    await this.modalCtrl.dismiss({});
  }
  async templatedata(data: any) {
    await this.modalCtrl.dismiss({ data });
  }
}
