import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'clients',
    loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsPageModule)
  },
  {
    path: 'new-client',
    loadChildren: () => import('./pages/new-client/new-client.module').then(m => m.NewClientPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/quotation/categories/categories.module').then(m => m.CategoriesPageModule)
  },
  {
    path: 'template/:category',
    loadChildren: () => import('./pages/quotation/template/template.module').then(m => m.TemplatePageModule)
  },
  {
    path: 'new-template/:category',
    loadChildren: () => import('./pages/quotation/new-template/new-template.module').then(m => m.NewTemplatePageModule)
  },
  {
    path: 'edit-template/:templateid/:category',
    loadChildren: () => import('./pages/quotation/new-template/new-template.module').then(m => m.NewTemplatePageModule)
  },
  {
    path: 'quotations',
    loadChildren: () => import('./pages/quotation/quotations/quotations.module').then(m => m.QuotationsPageModule)
  },
  {
    path: 'new-quotation',
    loadChildren: () => import('./pages/quotation/new-quotation/new-quotation.module').then(m => m.NewQuotationPageModule)
  },
  {
    path: 'category-template',
    loadChildren: () => import('./pages/quotation/category-template/category-template.module').then(m => m.CategoryTemplatePageModule)
  },
  {
    path: 'qtnitems/:qtnid',
    loadChildren: () => import('./pages/quotation/qtnitems/qtnitems.module').then(m => m.QtnitemsPageModule)
  },
  {
    path: 'new-qtnitem/:qtnid',
    loadChildren: () => import('./pages/quotation/new-qtnitem/new-qtnitem.module').then(m => m.NewQtnitemPageModule)
  },
  {
    path: 'qtninfo-one/:qtnid',
    loadChildren: () => import('./pages/quotation/qtninfo-one/qtninfo-one.module').then(m => m.QtninfoOnePageModule)
  },
  {
    path: 'qtninfo-two/:qtnid',
    loadChildren: () => import('./pages/quotation/qtninfo-two/qtninfo-two.module').then(m => m.QtninfoTwoPageModule)
  },
  {
    path: 'qtninfo-three/:qtnid',
    loadChildren: () => import('./pages/quotation/qtninfo-three/qtninfo-three.module').then(m => m.QtninfoThreePageModule)
  },
  {
    path: 'qtninfo-four/:qtnid',
    loadChildren: () => import('./pages/quotation/qtninfo-four/qtninfo-four.module').then(m => m.QtninfoFourPageModule)
  },
  {
    path: 'qtninfo-five/:qtnid',
    loadChildren: () => import('./pages/quotation/qtninfo-five/qtninfo-five.module').then(m => m.QtninfoFivePageModule)
  },
  {
    path: 'template-cate',
    loadChildren: () => import('./pages/quotation/template-cate/template-cate.module').then(m => m.TemplateCatePageModule)
  },
  {
    path: 'qrtamplate-category',
    loadChildren: () => import('./pages/repair-quotation/qrtamplate-category/qrtamplate-category.module').then(m => m.QrtamplateCategoryPageModule)
  },
  {
    path: 'qr-template/:qrcate',
    loadChildren: () => import('./pages/repair-quotation/qr-template/qr-template.module').then(m => m.QrTemplatePageModule)
  },
  {
    path: 'repair-quotation-form',
    loadChildren: () => import('./pages/repair-quotation/repair-quotation-form/repair-quotation-form.module').then(m => m.RepairQuotationFormPageModule)
  },
  {
    path: 'qr-item/:qtnid',
    loadChildren: () => import('./pages/repair-quotation/qr-item/qr-item.module').then(m => m.QrItemPageModule)
  },
  {
    path: 'qr-info-one/:qtnid',
    loadChildren: () => import('./pages/repair-quotation/qr-info-one/qr-info-one.module').then(m => m.QrInfoOnePageModule)
  },
  {
    path: 'qrnew-template/:qrcate',
    loadChildren: () => import('./pages/repair-quotation/qrnew-template/qrnew-template.module').then(m => m.QrnewTemplatePageModule)
  },
  {
    path: 'qredit-template/:qrcate/:rqtnid',
    loadChildren: () => import('./pages/repair-quotation/qrnew-template/qrnew-template.module').then(m => m.QrnewTemplatePageModule)
  },
  {
    path: 'chektemplate',
    loadChildren: () => import('./pages/repair-quotation/chektemplate/chektemplate.module').then(m => m.ChektemplatePageModule)
  },
  {
    path: 'listtemplate',
    loadChildren: () => import('./pages/repair-quotation/listtemplate/listtemplate.module').then(m => m.ListtemplatePageModule)
  },
  {
    path: 'view-repairqtn/:qtnid',
    loadChildren: () => import('./pages/repair-quotation/view-repairqtn/view-repairqtn.module').then(m => m.ViewRepairqtnPageModule)
  },
  {
    path: 'rquotations',
    loadChildren: () => import('./pages/repair-quotation/rquotations/rquotations.module').then( m => m.RquotationsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
