import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { HttpClientModule } from '@angular/common/http';
import { IonicSelectableModule } from 'ionic-selectable';
import { CategoryTemplatePageModule } from './pages/quotation/category-template/category-template.module';
import { TemplateCatePageModule } from './pages/quotation/template-cate/template-cate.module';
import { ChektemplatePageModule } from './pages/repair-quotation/chektemplate/chektemplate.module';
import { ListtemplatePageModule } from './pages/repair-quotation/listtemplate/listtemplate.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    IonicSelectableModule,
    CategoryTemplatePageModule,
    TemplateCatePageModule,
    ChektemplatePageModule,
    ListtemplatePageModule

  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule { }
