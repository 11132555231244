import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/data-types/user.object';
import { UserService } from 'src/app/services/user/user.service';
import { NavController } from '@ionic/angular';


@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  user: User = new User();
  mobileverified = false;
  otpverified = false;
  mobile: string = '';
  otp: string = '';

  constructor(
    private userServ: UserService,
    private navCtrl: NavController,
  ) { }
  async ngOnInit() {
    await this.userServ.user.subscribe(async u => {
      this.user = u;

    });
  }
  changepwd() {
    this.navCtrl.navigateRoot('/change-password');
  }
  async logout() {
    await this.userServ.logout();
    this.mobileverified = false;
    this.otpverified = false;
    this.mobile = '';
    this.otp = '';
    this.navCtrl.navigateRoot('/');
  }

}
